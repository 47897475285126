//
// Themes variables
// --------------------------------------------------

// xWork Theme

$theme-xwork-primary:                       #343a40 !default;
$theme-xwork-light:                         lighten($theme-xwork-primary, 25%) !default;
$theme-xwork-lighter:                       lighten($theme-xwork-primary, 50%) !default;
$theme-xwork-dark:                          darken($theme-xwork-primary, 10%) !default;
$theme-xwork-darker:                        darken($theme-xwork-primary, 20%) !default;

$theme-xwork-body-bg:                       #f5f5f5 !default;
$theme-xwork-body-bg-dark:                  darken($theme-xwork-body-bg, 4%) !default;
$theme-xwork-body-bg-light:                 lighten($theme-xwork-body-bg, 2%) !default;

$theme-xwork-body-color:                    $body-color !default;
$theme-xwork-body-color-dark:               $body-color-dark !default;
$theme-xwork-body-color-light:              $theme-xwork-body-bg-dark !default;

$theme-xwork-link-color:                    #0665d0 !default;
$theme-xwork-link-hover-color:              darken(#0665d0, 20%) !default;

$theme-xwork-headings-color:                $theme-xwork-body-color-dark !default;

$theme-xwork-input-btn-focus-color:         rgba($theme-xwork-primary, .25) !default;
$theme-xwork-input-btn-focus-box-shadow:    0 0 0 $input-btn-focus-width $theme-xwork-input-btn-focus-color !default;

$theme-xwork-input-bg:                      $white !default;
$theme-xwork-input-color:                   $theme-xwork-body-color !default;
$theme-xwork-input-border-color:            darken($theme-xwork-body-bg, 8%) !default;

$theme-xwork-input-focus-bg:                $white !default;
$theme-xwork-input-focus-color:             $theme-xwork-body-color-dark !default;
$theme-xwork-input-focus-border-color:      lighten($theme-xwork-primary, 25%) !default;

$theme-xwork-header-bg:                     $white !default;
$theme-xwork-header-dark-bg:                $theme-xwork-primary !default;
$theme-xwork-sidebar-bg:                    $white !default;
$theme-xwork-sidebar-dark-bg:               #35393e !default;
$theme-xwork-side-overlay-bg:               $white !default;

$theme-xwork-main-nav-link-icon-color:      $theme-xwork-light !default;
$theme-xwork-main-nav-link-hover-bg:        gray("200") !default;
$theme-xwork-main-nav-submenu-bg:           gray("100") !default;

$theme-xwork-main-nav-link-icon-dark-color: $theme-xwork-lighter !default;
$theme-xwork-main-nav-link-dark-hover-bg:   darken($theme-xwork-sidebar-dark-bg, 10%) !default;
$theme-xwork-main-nav-submenu-dark-bg:      darken($theme-xwork-sidebar-dark-bg, 5%) !default;


// xModern Theme

$theme-xmodern-primary:                       #3b5998 !default;
$theme-xmodern-light:                         lighten($theme-xmodern-primary, 15%) !default;
$theme-xmodern-lighter:                       lighten($theme-xmodern-primary, 35%) !default;
$theme-xmodern-dark:                          darken($theme-xmodern-primary, 10%) !default;
$theme-xmodern-darker:                        darken($theme-xmodern-primary, 20%) !default;

$theme-xmodern-body-bg:                       #f5f6fa !default;
$theme-xmodern-body-bg-dark:                  darken($theme-xmodern-body-bg, 4%) !default;
$theme-xmodern-body-bg-light:                 lighten($theme-xmodern-body-bg, 2%) !default;

$theme-xmodern-body-color:                    $body-color !default;
$theme-xmodern-body-color-dark:               $body-color-dark !default;
$theme-xmodern-body-color-light:              $theme-xmodern-body-bg-dark !default;

$theme-xmodern-link-color:                    $theme-xmodern-primary !default;
$theme-xmodern-link-hover-color:              darken($theme-xmodern-primary, 20%) !default;

$theme-xmodern-headings-color:                $theme-xmodern-body-color-dark !default;

$theme-xmodern-input-btn-focus-color:         rgba($theme-xmodern-primary, .25) !default;
$theme-xmodern-input-btn-focus-box-shadow:    0 0 0 $input-btn-focus-width $theme-xmodern-input-btn-focus-color !default;

$theme-xmodern-input-bg:                      $white !default;
$theme-xmodern-input-color:                   $theme-xmodern-body-color !default;
$theme-xmodern-input-border-color:            darken($theme-xmodern-body-bg, 8%) !default;

$theme-xmodern-input-focus-bg:                $white !default;
$theme-xmodern-input-focus-color:             $theme-xmodern-body-color-dark !default;
$theme-xmodern-input-focus-border-color:      lighten($theme-xmodern-primary, 25%) !default;

$theme-xmodern-header-bg:                     $white !default;
$theme-xmodern-header-dark-bg:                $theme-xmodern-primary !default;
$theme-xmodern-sidebar-bg:                    $white !default;
$theme-xmodern-sidebar-dark-bg:               #35383e !default;
$theme-xmodern-side-overlay-bg:               $white !default;

$theme-xmodern-main-nav-link-icon-color:      $theme-xmodern-primary !default;
$theme-xmodern-main-nav-link-hover-bg:        lighten($theme-xmodern-primary, 48%) !default;
$theme-xmodern-main-nav-submenu-bg:           lighten($theme-xmodern-primary, 54%) !default;

$theme-xmodern-main-nav-link-icon-dark-color: $theme-xmodern-lighter !default;
$theme-xmodern-main-nav-link-dark-hover-bg:   darken($theme-xmodern-sidebar-dark-bg, 8%) !default;
$theme-xmodern-main-nav-submenu-dark-bg:      darken($theme-xmodern-sidebar-dark-bg, 3%) !default;


// xEco Theme

$theme-xeco-primary:                       #689550 !default;
$theme-xeco-light:                         lighten($theme-xeco-primary, 15%) !default;
$theme-xeco-lighter:                       lighten($theme-xeco-primary, 35%) !default;
$theme-xeco-dark:                          darken($theme-xeco-primary, 10%) !default;
$theme-xeco-darker:                        darken($theme-xeco-primary, 20%) !default;

$theme-xeco-body-bg:                       #fcf8f5 !default;
$theme-xeco-body-bg-dark:                  darken($theme-xeco-body-bg, 4%) !default;
$theme-xeco-body-bg-light:                 lighten($theme-xeco-body-bg, 1%) !default;

$theme-xeco-body-color:                    #574f49 !default;
$theme-xeco-body-color-dark:               $body-color-dark !default;
$theme-xeco-body-color-light:              $theme-xeco-body-bg-dark !default;

$theme-xeco-link-color:                    $theme-xeco-primary !default;
$theme-xeco-link-hover-color:              darken($theme-xeco-primary, 20%) !default;

$theme-xeco-headings-color:                $theme-xeco-body-color-dark !default;

$theme-xeco-input-btn-focus-color:         rgba($theme-xeco-primary, .25) !default;
$theme-xeco-input-btn-focus-box-shadow:    0 0 0 $input-btn-focus-width $theme-xeco-input-btn-focus-color !default;

$theme-xeco-input-bg:                      $white !default;
$theme-xeco-input-color:                   $theme-xeco-body-color !default;
$theme-xeco-input-border-color:            darken($theme-xeco-body-bg, 8%) !default;

$theme-xeco-input-focus-bg:                $white !default;
$theme-xeco-input-focus-color:             $theme-xeco-body-color-dark !default;
$theme-xeco-input-focus-border-color:      lighten($theme-xeco-primary, 25%) !default;

$theme-xeco-header-bg:                     $white !default;
$theme-xeco-header-dark-bg:                $theme-xeco-primary !default;
$theme-xeco-sidebar-bg:                    $white !default;
$theme-xeco-sidebar-dark-bg:               #403934 !default;
$theme-xeco-side-overlay-bg:               $white !default;

$theme-xeco-main-nav-link-icon-color:      $theme-xeco-primary !default;
$theme-xeco-main-nav-link-hover-bg:        $theme-xeco-body-bg-dark !default;
$theme-xeco-main-nav-submenu-bg:           $theme-xeco-body-bg !default;

$theme-xeco-main-nav-link-icon-dark-color: $theme-xeco-lighter !default;
$theme-xeco-main-nav-link-dark-hover-bg:   darken($theme-xeco-sidebar-dark-bg, 10%) !default;
$theme-xeco-main-nav-submenu-dark-bg:      darken($theme-xeco-sidebar-dark-bg, 5%) !default;


// xSmooth Theme

$theme-xsmooth-primary:                       #774aa4 !default;
$theme-xsmooth-light:                         lighten($theme-xsmooth-primary, 15%) !default;
$theme-xsmooth-lighter:                       lighten($theme-xsmooth-primary, 32%) !default;
$theme-xsmooth-dark:                          darken($theme-xsmooth-primary, 10%) !default;
$theme-xsmooth-darker:                        darken($theme-xsmooth-primary, 20%) !default;

$theme-xsmooth-body-bg:                       #f7f5fa !default;
$theme-xsmooth-body-bg-dark:                  darken($theme-xsmooth-body-bg, 4%) !default;
$theme-xsmooth-body-bg-light:                 lighten($theme-xsmooth-body-bg, 2%) !default;

$theme-xsmooth-body-color:                    $body-color !default;
$theme-xsmooth-body-color-dark:               $body-color-dark !default;
$theme-xsmooth-body-color-light:              $theme-xsmooth-body-bg-dark !default;

$theme-xsmooth-link-color:                    $theme-xsmooth-primary !default;
$theme-xsmooth-link-hover-color:              darken($theme-xsmooth-primary, 20%) !default;

$theme-xsmooth-headings-color:                $theme-xsmooth-body-color-dark !default;

$theme-xsmooth-input-btn-focus-color:         rgba($theme-xsmooth-primary, .25) !default;
$theme-xsmooth-input-btn-focus-box-shadow:    0 0 0 $input-btn-focus-width $theme-xsmooth-input-btn-focus-color !default;

$theme-xsmooth-input-bg:                      $white !default;
$theme-xsmooth-input-color:                   $theme-xsmooth-body-color !default;
$theme-xsmooth-input-border-color:            darken($theme-xsmooth-body-bg, 8%) !default;

$theme-xsmooth-input-focus-bg:                $white !default;
$theme-xsmooth-input-focus-color:             $theme-xsmooth-body-color-dark !default;
$theme-xsmooth-input-focus-border-color:      lighten($theme-xsmooth-primary, 25%) !default;

$theme-xsmooth-header-bg:                     $white !default;
$theme-xsmooth-header-dark-bg:                $theme-xsmooth-primary !default;
$theme-xsmooth-sidebar-bg:                    $white !default;
$theme-xsmooth-sidebar-dark-bg:               #39353e !default;
$theme-xsmooth-side-overlay-bg:               $white !default;

$theme-xsmooth-main-nav-link-icon-color:      $theme-xsmooth-primary !default;
$theme-xsmooth-main-nav-link-hover-bg:        $theme-xsmooth-body-bg-dark !default;
$theme-xsmooth-main-nav-submenu-bg:           $theme-xsmooth-body-bg !default;

$theme-xsmooth-main-nav-link-icon-dark-color: $theme-xsmooth-lighter !default;
$theme-xsmooth-main-nav-link-dark-hover-bg:   darken($theme-xsmooth-sidebar-dark-bg, 10%) !default;
$theme-xsmooth-main-nav-submenu-dark-bg:      darken($theme-xsmooth-sidebar-dark-bg, 5%) !default;


// xInspire Theme

$theme-xinspire-primary:                       #00a680 !default;
$theme-xinspire-light:                         #36c396 !default;
$theme-xinspire-lighter:                       lighten($theme-xinspire-light, 15%) !default;
$theme-xinspire-dark:                          darken($theme-xinspire-primary, 7.5%) !default;
$theme-xinspire-darker:                        darken($theme-xinspire-primary, 15%) !default;

$theme-xinspire-body-bg:                       #f0f5f3 !default;
$theme-xinspire-body-bg-dark:                  darken($theme-xinspire-body-bg, 4%) !default;
$theme-xinspire-body-bg-light:                 lighten($theme-xinspire-body-bg, 2%) !default;

$theme-xinspire-body-color:                    $body-color !default;
$theme-xinspire-body-color-dark:               $body-color-dark !default;
$theme-xinspire-body-color-light:              $theme-xinspire-body-bg-dark !default;

$theme-xinspire-link-color:                    $theme-xinspire-primary !default;
$theme-xinspire-link-hover-color:              darken($theme-xinspire-primary, 20%) !default;

$theme-xinspire-headings-color:                $theme-xinspire-body-color-dark !default;

$theme-xinspire-input-btn-focus-color:         rgba($theme-xinspire-primary, .25) !default;
$theme-xinspire-input-btn-focus-box-shadow:    0 0 0 $input-btn-focus-width $theme-xinspire-input-btn-focus-color !default;

$theme-xinspire-input-bg:                      $white !default;
$theme-xinspire-input-color:                   $theme-xinspire-body-color !default;
$theme-xinspire-input-border-color:            darken($theme-xinspire-body-bg, 8%) !default;

$theme-xinspire-input-focus-bg:                $white !default;
$theme-xinspire-input-focus-color:             $theme-xinspire-body-color-dark !default;
$theme-xinspire-input-focus-border-color:      lighten($theme-xinspire-primary, 10%) !default;

$theme-xinspire-header-bg:                     $white !default;
$theme-xinspire-header-dark-bg:                $theme-xinspire-primary !default;
$theme-xinspire-sidebar-bg:                    $white !default;
$theme-xinspire-sidebar-dark-bg:               #353e3c !default;
$theme-xinspire-side-overlay-bg:               $white !default;

$theme-xinspire-main-nav-link-icon-color:      $theme-xinspire-primary !default;
$theme-xinspire-main-nav-link-hover-bg:        $theme-xinspire-body-bg-dark !default;
$theme-xinspire-main-nav-submenu-bg:           $theme-xinspire-body-bg !default;

$theme-xinspire-main-nav-link-icon-dark-color: $theme-xinspire-lighter !default;
$theme-xinspire-main-nav-link-dark-hover-bg:   darken($theme-xinspire-sidebar-dark-bg, 10%) !default;
$theme-xinspire-main-nav-submenu-dark-bg:      darken($theme-xinspire-sidebar-dark-bg, 5%) !default;