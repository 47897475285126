//
// Easy Pie Chart
//
// Overwrite/Extend styles
// --------------------------------------------------

.pie-chart {
    position: relative;
    display: block;

    > canvas {
        display: block;
        margin: 0 auto;
    }

    > span {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        text-align: center;
        transform: translateY(-50%);
    }
}
