//
// Dashmix - UI Framework
// --------------------------------------------------

// Bootstrap functions
@import 'bootstrap/functions';

// User variables (your own variable overrides)
@import 'custom/variables';

// Custom Bootstrap variables overrides
@import 'dashmix/variables-bootstrap';

// Bootstrap variables
@import 'bootstrap/variables';

// Bootstrap mixins
@import 'bootstrap/mixins';

// Custom mixins and Bootstrap overrides
@import 'dashmix/mixins';

// Bootstrap components
//@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/input-group';
@import 'bootstrap/custom-forms';
@import 'bootstrap/nav';
//@import 'bootstrap/navbar';
//@import 'bootstrap/card';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/jumbotron';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip';
@import 'bootstrap/popover';
//@import 'bootstrap/carousel';
@import 'bootstrap/utilities';
@import 'bootstrap/print';

// Custom variables
@import 'dashmix/variables';
@import 'dashmix/variables-themes';

// Extend Bootstrap styles and override the ones..
// ..we can't alter with the provided variables
@import 'dashmix/reboot';
@import 'dashmix/type';
@import 'dashmix/grid';
@import 'dashmix/tables';
@import 'dashmix/forms';
@import 'dashmix/buttons';
@import 'dashmix/transitions';
@import 'dashmix/dropdown';
@import 'dashmix/input-group';
@import 'dashmix/custom-forms';
@import 'dashmix/nav';
@import 'dashmix/breadcrumb';
@import 'dashmix/pagination';
@import 'dashmix/badge';
@import 'dashmix/modal';
@import 'dashmix/print';

// Custom layout
@import 'dashmix/layout';
@import 'dashmix/header';
@import 'dashmix/sidebar';
@import 'dashmix/side-overlay';
@import 'dashmix/layout-variations';
@import 'dashmix/hero';
@import 'dashmix/block';

// Custom components
@import 'dashmix/page-loader';
@import 'dashmix/nav-main';
@import 'dashmix/images';
@import 'dashmix/lists';
@import 'dashmix/item';
@import 'dashmix/overlay';
@import 'dashmix/timeline';
@import 'dashmix/ribbon';

// Utilities
@import 'dashmix/utilities';

// Core third party components
@import 'vendor/animate';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/fontawesome/regular';
@import 'vendor/fontawesome/solid';
@import 'vendor/fontawesome/brands';
@import 'vendor/simple-line-icons';
@import 'vendor/simplebar';

// Big Vendor CSS
@import 'vendor_main/select2/select2';
@import 'vendor_main/bootstrap-datepicker/bootstrap-datepicker3.css';

// Optional third party plugins (style overrides)
@import 'vendor/bootstrap-datepicker';
// @import 'vendor/ckeditor';
// @import 'vendor/dropzone';
// @import 'vendor/datatables';
@import 'vendor/easy-pie-chart';
// @import 'vendor/fullcalendar';
// @import 'vendor/ion-range-slider';
// @import 'vendor/jquery-sparkline';
// @import 'vendor/jvector-map';
@import 'vendor/select2';
// @import 'vendor/simplemde';
@import 'vendor/summernote';
// @import 'vendor/slick';

// User styles (your own styles/overrides)
@import 'custom/main';
@import 'pages/sms';
