//
// Page Loader
// --------------------------------------------------

#page-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: theme-color("primary");
    z-index: 999998;
    transform: translateY(-100%);

    &.show {
        transform: translateY(0);
    }

    &::after,
    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        border-radius: 100%;
        content: '';
        z-index: 999999;
    }

    &::after {
        margin-top: -160px;
        margin-left: -160px;
        width: 320px;
        height: 320px;
        background-color: rgba($white, .5);
        border: 4px solid rgba($white, .75);
        box-shadow: 0 0 100px rgba($black, .15);
        animation: page-loader-inner 1.25s infinite ease-out;
    }

    &::before {
        margin-top: -40px;
        margin-left: -40px;
        width: 80px;
        height: 80px;
        background-color: rgba($white, 1);
        animation: page-loader-outer 1.25s infinite ease-in;
    }
}


@keyframes page-loader-inner {
    0%, 40% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes page-loader-outer {
    0% {
        transform: scale(1);
    }

    40%, 100% {
        transform: scale(0);
        opacity: 0;
    }
}
