// Schedule
#schedule-list-con {
    .btn-1 {
        width: 60px;
    }

    .day-range {
        button {
            background: transparent;
            border: none;
            color: $custom-primary;
            font-size: 33px;
            cursor: pointer; 
        }
    }

    .schedule-status {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        font-weight: bold;
        text-align: left;

        .schedule-status-0 {
            .bullet {
                background-color: $danger;
            }
        }
        .schedule-status-1 {
            .bullet {
                background-color: $info;
            }
        }
        .bullet {
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
            width: 12px;
            height: 12px;
        }
    }
}

#schedule-detail-modal {
    .schedule-edit {
        display: none;
    }

    .sale-convert {
        display: none;
    }
    
    &.edit-active {
        .schedule-edit {
            display: block;
        }

        .schedule-detail {
            display: none;
        }
    }

    &.sale-active {
        .sale-convert {
            display: block;
        }

        .schedule-detail {
            display: none;
        }
    }

    &.status-complete {
        .modal-footer-button .btn-dark, .btn-secondary {
            display: none;
        }
    }
}

.payment-input.inactive {
    display: none;
}

// Schedule status
.text-schedule-status-0 {
    color: $danger;
}
.text-schedule-status-1 {
    color: $info;
}

.weekday-grid-more-schedules {
  float: right;
  margin-top: 4px;
  margin-right: 6px;
  height: 18px;
  line-height: 17px;
  padding: 0 5px;
  border-radius: 3px;
  border: 0;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #ff0000;
}

.tui-full-calendar-month-more-title {
    border-bottom: 1px solid #d5d5d5!important;
    padding: 6px 17px 0 17px!important;
    text-align: center!important;
}
.tui-full-calendar-weekday-schedule-title {
    font-weight: inherit;
}
.tui-full-calendar-month-dayname-item {
    text-align:center;
}
