.search-result {
    position: absolute;
    top: 39px;
    background: #FFFFFF;
    left: 44px;
    right: 0;
    border: 1px #EEE solid;
    color: #333;
    display: none;
    
    & > div {
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            background-color: #EEE;
        }
    }
}