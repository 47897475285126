//
// Breadcrumb
// --------------------------------------------------

.breadcrumb.breadcrumb-alt {
    .breadcrumb-item + .breadcrumb-item::before {
        position: relative;
        top: 1px;
        width: 1.25rem;
        font-family: "simple-line-icons";
        font-size: $font-size-sm;
        content: "\e606";
    }
}
