.banner-item {
    .img-wrapper {
        height: 220px;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            max-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}