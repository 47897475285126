﻿@import "theme";
@import "schedule";
@import "tablemini";
@import "search";
@import "select2";
@import "banner";

.text-deleted {
    color: #8d9298 !important;

    a {
        opacity: 0.7;
    }
}

.nav-main-divider {
    height: 1px;
    background: #EEE;
    margin: 15px 25px 15px 10px;
}

// Company Request 
.company-request-block {
    .requested-btn {
        display: none;
    }

    &.requested {
        .requested-btn {
            display: inline-block;
        }

        .request-btn {
            display: none;
        }
    }
}

.modal-company-request {
    .btn-approve, .btn-decline, .btn-delete {
        display: none;
    }

    &.modal-company-request-direction-1.modal-company-request-0 {
        .btn-approve, .btn-decline {
            display: inline-block;
        }
    }

    &.modal-company-request-direction-2.modal-company-request-0 {
        .btn-delete {
            display: inline-block;
        }
    }
}

.badge {
    &.crequest-1 {
        background-color: $custom-primary;
    }
    &.crequest-2 {
        background-color: $red;
    }
}

// Customer 
.btn-customer-register {
    width: 120px;
    height: 75px;
    border-radius: 0;
}

// Calendar Overwrite
.tui-full-calendar-weekday-schedule-bullet {
    width: 10px;
    height: 10px;
}

.tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time .tui-full-calendar-weekday-schedule-title {
    padding-left: 12px;
}

.tui-full-calendar-time-schedule {
    border-left-width: 5px;
}

// SMS Template
.sms-template-block {
    .block-header {
        background-color: #F9F9F9;
        height: 60px;
    }

    textarea {
        height: 150px;
    }
}

.manual-sms-content {
    display: none;

    &.active {
        display: block;
    }
}

// Mini payment
.mini-payment {
    display: inline-block;
    padding: 2px 7px;
    color: #FFF;
    border-radius: 3px;

    &.mini-payment-card {
        background-color: #44ced8;
    }

    &.mini-payment-cash {
        background-color: #b1cf31;
    }

    &.mini-payment-package {
        background-color: #d9426b;
    }

    &.mini-payment-other {
        background-color: #212529;
    }
}
.input-sale {
    height: 35.25px;
}
.btn-sale {
    vertical-align: top;
}
.table-sale {
    tbody {
        tr {
            td {
                padding: 1.2rem 0.75rem;
            }
        }
    }
}

// Chart
.chart-block {
    width: 140px;
    margin-bottom: 0 !important;
    vertical-align: top;
}

// Tags 
#tags-container {
    margin-bottom: 0.5rem;

    .badge {
        border-radius: 3px;
        background: #fbfbfb;
        color: #525252;
        border: #d1d1d1 1px solid;
        padding: 6px 8px;

        & + .badge {
            margin-left: 10px;
        }

        span {
            cursor: pointer;
            display: inline-block;
        }
    }
}

// Menu - Customize
.nav-main-link {
    position: relative;

    .nav-tooltip {
        position: absolute;
        left: 80%;
        display: inline-block;
        padding: 6px 9px;
        font-size: 14px;
        font-weight: normal;
        color: #838383;
        background: #d8d8d8;
        border-radius: 6px;

        &::before {
            content: '';
            display: inline-block;
            width: 40px;
            height: 18px;
            background: linear-gradient(45deg, black 25%, transparent 25%, transparent), linear-gradient(-45deg, black 25%, transparent 25%, transparent), linear-gradient(45deg, transparent 75%, black 75%), linear-gradient(-45deg, transparent 75%, black 75%);
            background-position: 20px 0;
            background-size: 40px 35px;
        }
    }
}

// Umbaa
.modal-content {
    border-radius: 0;
}

.m-btn-01 {
    font-size:1.5rem;
    padding:1rem 0.75rem;
    border-radius: 0;

    &:focus, &.focus {
        box-shadow: none;
    }
}

.margin-edit {
    margin-right: 0.25rem !important;
}

// Top search style 
.info-box {
    border-top:1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    
    .first-box {
        background-color: $custom-primary;
        color:#FFF;
        padding: 18px 14px;
    }

    .second-box {
        border-right: 1px solid #D4D4D4;
        border-left: 1px solid #D4D4D4;
    }
    .third-box {
        a {
            font-size: 45px;
            color:#C7C7C7;
        }
    }
}

#customer-detail-modal {
    .c-body {
        display: none;

        &.active {
            display: block;
        }
    }

    button.back {
        background: none;
        opacity: 0.5;
        border: none;
        font-size: 25px;
        cursor: pointer;
        position: absolute;
        top: 30px;
        outline: none;
    }

    .info-table {
        max-height: 200px;
        overflow: hidden;

        .table {
            tbody.c-sales {
                font-size: 13px;
                td {
                    padding: 0.55rem 0.35rem;
                }
            }
        }
    }
}

// Modal header text title 
.modal-header {
    h5 {
        width:100%;
    }
    .close {
        position: absolute;
        right:17px;
    }
}

// Modal little size
@media (min-width: 576px){
    .modal-little-size{
        max-width: 340px;
    }
}

.pagination {
    justify-content: flex-end!important;
}

// Select for Safari
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

// Custom
table.table-nobordered {
    th, td {
        border-top: 0;
    }

    thead th {
        border-bottom: 0;
    }
}

.p-30 {
    padding: 30px;
}

.fs16 {
    font-size: 16px;
}

.fs12 {
    font-size: 12px;
}

.pt-50 {
    padding-top:50px;
}

.mt-50 {
    margin-top:50px;  
}