.table-mini {
    th, td {
        padding: 0.4rem 0.5rem;
    }
    
    .form-control {
        padding: 0.2rem 0.3rem;
    }
}

@include media-breakpoint-down(md) {
    .table-custom {    
        min-width: 600px; 
        display: table;
    }
}