$custom-primary:                       #19aaaf !default;
$custom-light:                         lighten($custom-primary, 15%) !default;
$custom-lighter:                       lighten($custom-primary, 35%) !default;
$custom-dark:                          darken($custom-primary, 10%) !default;
$custom-darker:                        darken($custom-primary, 20%) !default;

$custom-body-bg:                       #f5f6fa !default;
$custom-body-bg-dark:                  darken($custom-body-bg, 4%) !default;
$custom-body-bg-light:                 lighten($custom-body-bg, 2%) !default;

$custom-body-color:                    $body-color !default;
$custom-body-color-dark:               $body-color-dark !default;
$custom-body-color-light:              $custom-body-bg-dark !default;

$custom-link-color:                    $custom-primary !default;
$custom-link-hover-color:              darken($custom-primary, 20%) !default;

$custom-headings-color:                $custom-body-color-dark !default;

$custom-input-btn-focus-color:         rgba($custom-primary, .25) !default;
$custom-input-btn-focus-box-shadow:    0 0 0 $input-btn-focus-width $custom-input-btn-focus-color !default;

$custom-input-bg:                      $white !default;
$custom-input-color:                   $custom-body-color !default;
$custom-input-border-color:            darken($custom-body-bg, 8%) !default;

$custom-input-focus-bg:                $white !default;
$custom-input-focus-color:             $custom-body-color-dark !default;
$custom-input-focus-border-color:      lighten($custom-primary, 25%) !default;

$custom-header-bg:                     $white !default;
$custom-header-dark-bg:                $custom-primary !default;
$custom-sidebar-bg:                    $white !default;
$custom-sidebar-dark-bg:               #5b5b5b !default;
$custom-side-overlay-bg:               $white !default;

$custom-main-nav-link-icon-color:      $custom-primary !default;
$custom-main-nav-link-hover-bg:        lighten($custom-primary, 48%) !default;
$custom-main-nav-submenu-bg:           lighten($custom-primary, 54%) !default;

$custom-main-nav-link-icon-dark-color: $custom-lighter !default;
$custom-main-nav-link-dark-hover-bg:   darken($custom-sidebar-dark-bg, 8%) !default;
$custom-main-nav-submenu-dark-bg:      darken($custom-sidebar-dark-bg, 3%) !default;

@include color-theme( $custom-primary,
                        $custom-light,
                        $custom-lighter,
                        $custom-dark,
                        $custom-darker,
                        $custom-body-bg,
                        $custom-body-bg-dark,
                        $custom-body-bg-light,
                        $custom-body-color,
                        $custom-body-color-dark,
                        $custom-body-color-light,
                        $custom-link-color,
                        $custom-link-hover-color,
                        $custom-headings-color,
                        $custom-input-btn-focus-color,
                        $custom-input-btn-focus-box-shadow,
                        $custom-input-bg,
                        $custom-input-color,
                        $custom-input-border-color,
                        $custom-input-focus-bg,
                        $custom-input-focus-color,
                        $custom-input-focus-border-color,
                        $custom-header-bg,
                        $custom-header-dark-bg,
                        $custom-sidebar-bg,
                        $custom-sidebar-dark-bg,
                        $custom-side-overlay-bg,
                        $custom-main-nav-link-icon-color,
                        $custom-main-nav-link-hover-bg,
                        $custom-main-nav-submenu-bg,
                        $custom-main-nav-link-icon-dark-color,
                        $custom-main-nav-link-dark-hover-bg,
                        $custom-main-nav-submenu-dark-bg) ;